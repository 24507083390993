import styled from 'styled-components';
import React from 'react';
import FloorplanTitle from './FloorplanTitle';
import FloorplanMeta from './FloorplanMeta';
import FloorplanSoldOut from "./FloorplanSoldOut";
import { ReactComponent as PlusButton } from '../assets/Plus.svg';

const FloorplanTile = styled.div`
  width: 100%;
  cursor: pointer;
  >div {
    position: relative;
  }
  ${props => props.isSoldOut && `
    display: none;
  `};
`

const PlusButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: inherit;
  svg {
    cursor: pointer;
  }
`

const FloorplanImage = styled.div`
  width: 100%;
  padding-bottom: 80%;
  height: 0;
  position: relative;
  margin: 10px 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
  }
`

export default ({ floorplan, toggle }) => {
  const onClick = () => {
    if (toggle) {
      toggle(floorplan);
    }
  }

  return <FloorplanTile className="floorplanTile"  onClick={ onClick } isSoldOut={ floorplan.isSoldOut }>
    <div>
      { floorplan.isSoldOut && <FloorplanSoldOut/> }
      <FloorplanTitle plan={ floorplan.name } unit={ floorplan.unit } collection={ floorplan.collection } />
      <FloorplanImage><img src={ floorplan.image } alt="" /></FloorplanImage>
      <FloorplanMeta bedrooms={ floorplan.bedrooms } extraRoom={ floorplan.extraRoom } interiorSqft={ floorplan.interiorSqft } exteriorSqft={ floorplan.exteriorSqft } />
      <PlusButtonWrap><PlusButton /></PlusButtonWrap>
    </div>
  </FloorplanTile>
} 