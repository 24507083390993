import styled from 'styled-components';
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FloorplanTitle from './FloorplanTitle';
import FloorplanMeta from './FloorplanMeta';
import FloorplanSoldOut from "./FloorplanSoldOut";
import { ButtonSpan } from './Typography';
import { ReactComponent as CloseButton } from '../assets/MenuClose.svg';
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/dist/plugins/video";
import "yet-another-react-lightbox/dist/styles.css";

const FloorplanDetails = styled.div`
  width: calc(100% - 60px);
  min-height: calc(100vh - ${props => props.offsetHeight}px + 2px);
  position: relative;
  padding: 60px 0;
  margin: 0 30px;
  border-top: 1px solid black;

  .closeButton {
    position: absolute;
    top: 60px;
    right: 0;
    cursor: pointer;
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    border-top: none;
    border-bottom: 1px solid black;
    margin: 0 0 60px;
    padding: 60px 0 120px;
    width: 100%;

  }
  
  .floorplanSoldOut {
    /* for the shorter images, the 40% ensures that the sold 
    message is on top of the image */
    top: calc(40% - 21px); 
  }
  `

const OpacityWrapper = styled.div`
 ${props => props.isSoldOut && `
   opacity: 0.25;
 `};
`

const FloorplanInfo = styled.div`
  .floorplanMeta {
    margin: 42px 0;
  }
`

const FloorplanImage = styled.div`
  width: calc(100% - 300px);
  height: calc(100% - 120px);
  max-height: calc(100vh - 120px - ${props => props.offsetHeight}px);
  position: absolute;
  right: 20px; // to make room for the close button
  top: 60px;
  display: none;

  @media (min-width: calc(${props => props.theme.bp.medium})) {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    object-position: top;
  }
`

const FloorplanMobileImage = styled.div`
  margin: 60px 0;
  @media (min-width: calc(${props => props.theme.bp.medium})) {
    display: none;
  }

`

const ContextImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // margin-top: 60px;

  @media (min-width: calc(${props => props.theme.bp.medium})) {
    width: 280px;
    margin-top: 120px;
  } 
`
const ContextImage = styled.div`
  flex: 0 0 25%;
  margin-bottom: 15px;
  width: 100%;

  @media (min-width: calc(${props => props.theme.bp.medium})) {
    flex: 0 0 50%;
  } 
`

const PdfButton = styled.div`
  margin-bottom: 16px;
`

const GalleryButton = styled.div`
  margin-bottom: 16px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-family: proxima-nova, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
`

export default ({ floorplan, toggle, offsetHeight=0 }) => {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => {
    window.scrollTo({ top: ref.current.offsetTop + 2 - offsetHeight, behavior: 'smooth' }) 
  }, [offsetHeight])

  const onClick = () => {
    if (toggle) {
      toggle(floorplan, ref);
    }
  }

  const [open, setOpen] = React.useState(false);

  const slides = [];
  if (floorplan && Array.isArray(floorplan.gallery)) {
    floorplan.gallery.forEach((image) => {
      if (image.media_type === "video") {
        slides.push({
          type: image.media_type,
          sources: [
            {
              src: image.file_url,
              type: "video/mp4"
            }
          ]
        });
      } else if (image.media_type === "image") {
        slides.push({
          type: image.media_type,
          src: image.file_url
        });
      }
    });  
  }

  const sheet = "https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/TESORO-Feature-Sheet.pdf";//floorplan.featureSheetPdf || "https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/TES-012_Feature-Sheet_Digital_V1.pdf";


  return (
    <FloorplanDetails
      ref={ref}
      className="floorplanDetails"
      offsetHeight={offsetHeight}
    >
      <FloorplanInfo className={"floorplanInfo"}>
        <FloorplanTitle
          plan={floorplan.name}
          unit={floorplan.unit}
          collection={floorplan.collection}
        />
        <FloorplanMeta
          bedrooms={floorplan.bedrooms}
          extraRoom={floorplan.extraRoom}
          interiorSqft={floorplan.interiorSqft}
          exteriorSqft={floorplan.exteriorSqft}
          direction="column"
        />

        {floorplan.downloadPdf && (
          <PdfButton>
            <a
              href={floorplan.downloadPdf}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <ButtonSpan>{t("Download")}</ButtonSpan>
            </a>
          </PdfButton>
        )}
        <PdfButton>
          <a
            href={sheet}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <ButtonSpan>{t("Feature Sheet")}</ButtonSpan>
          </a>
        </PdfButton>

        {slides.length > 0 && (
          <>
            <GalleryButton>
              <button type="button" onClick={() => setOpen(true)}>
                <ButtonSpan>Suite Gallery</ButtonSpan>
              </button>
            </GalleryButton>
            <Lightbox
              plugins={[Video]}
              open={open}
              close={() => setOpen(false)}
              slides={slides}
            />
          </> 
        ) }

        <FloorplanMobileImage>
          <img src={floorplan.image} alt="" />
          {floorplan.isSoldOut && <FloorplanSoldOut />}
        </FloorplanMobileImage>

        <ContextImages>
          {floorplan.contextImages.map((image, idx) => {
            return (
              <ContextImage key={`context-${idx}`}>
                <img src={image} alt="" />
              </ContextImage>
            );
          })}
        </ContextImages>
      </FloorplanInfo>

      <FloorplanImage className={"floorplanImage"} offsetHeight={offsetHeight}>
        <OpacityWrapper isSoldOut={floorplan.isSoldOut}>
          <img src={floorplan.image} alt="" />
          {floorplan.isSoldOut && <FloorplanSoldOut />}
        </OpacityWrapper>
      </FloorplanImage>

      <CloseButton className="closeButton" onClick={onClick} />
    </FloorplanDetails>
  );
} 