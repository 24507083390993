import styled from 'styled-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';
import Image from '../components/Image';
import { HeroH1, HeroH2, H3, P } from '../components/Typography';
import { Section, PercentageSection, SpacedSection, InfoSection } from '../components/layout/Sections';

const SubNav = styled.div`
  position: absolute;
  top: 120px;
  left: 36px;
  z-index: 98;

  @media (min-width: ${props => props.theme.bp.medium}) {
    left: 60px
  }

  a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 2.5px;
    font-weight: 500;
    display: block;
  }
`

const Section2 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:last-child {
      margin-top: 165px;
    }
  }
`

const Section4 = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div {

      p {
        margin-top: 72px;
        text-align: center;
      }
    }
  }
`

const Section7 = styled(SpacedSection)`

  @media (min-width: ${props => props.theme.bp.large}) {
    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    >div:first-child p {
      max-width: 244px;
    }

    >div:last-child img {
      width: 82.462686%;
      margin-left: 17.537314%;
    }

    >div:last-child p {
      margin-bottom: 173px;
      max-width: 210px;
    }
  }
`

const Section71 = styled(SpacedSection)`

  @media (min-width: ${props => props.theme.bp.large}) {
    >div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    >div:first-child p {
      max-width: 244px;
    }
    >div:last-child p {
      margin-bottom: 229px;
      max-width: 210px;
    }
  }
`

const Section9 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child p {
      margin-bottom: 236px;
    }
  }

`

const Section11 = styled(SpacedSection)`  
  @media (min-width: ${props => props.theme.bp.large}) {
    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    >div:first-child p {
      max-width: 278px;
      margin-bottom: 425px;
    }
    >div:last-child p {
      margin-top: 149px;
      max-width: 321px;
    }
  }
`

const Section13 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div {
      display: flex;
      flex-direction: column;
    }
    >div:first-child {
      align-items: flex-end;
      p {
        max-width: 178px;
        margin: 225px 0;
      }
    }
    >div:last-child {
      justify-content: flex-end;
    }
  }
`

const Section15 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:last-child p {
      max-width: 208px;
      margin-bottom: 411px;
    }
  }
`

const Section16 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child p {
      max-width: 185px;
      margin-top: 179px;
    }
  }
`

const Section17 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:last-child p {
      max-width: 347px;
      margin-bottom: 306px;
    }
  }
`

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();
  const architectureRef = useRef();
  const interiorsRef = useRef();

  const navTo = (e, ref) => {
    e.preventDefault();
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
  }

  return <Page title="Residences" header={{ transitionRef }}>
    <SubNav>
      <a href="#" onClick={ e => navTo(e, architectureRef) }>{ t('Architecture') }</a>
      <a href="#" onClick={ e => navTo(e, interiorsRef) }>{ t('Interiors') }</a>
    </SubNav>
    <Hero ref={ transitionRef } coverImage="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/06/ResidencesHero.jpg">
      <HeroH1>
        { t('The Rarest of Treasures') }
      </HeroH1>
      <HeroH2>
        { t('Urban waterfront. Iconic architecture.') } <br/>
        { t('Exclusive homes.') }
      </HeroH2>
    </Hero>
    <InfoSection ref={ architectureRef } first={234} second={866}>
      <div>
        <div>
          <H3>{ t('Architecture') }</H3>
          <P>{ t('Landmark West Coast modern architecture presents a new icon to the city; its uniquely curved façade thoughtfully designed to open each waterfront home to these exceptional views.') }</P>
        </div>
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2025/03/residences-1-architecture.jpg" alt="" />
      </div>
    </InfoSection>

    <Section2 first={542} second={264} gutterSecond={125}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2025/03/residences-2-an-elegant-form.jpg" alt="" />
      </div>
      <div>
        <P>{ t('An elegant form, softened by natural elements, reflects the landscape with a distinctly contemporary character.') }</P>
      </div>
    </Section2>

    <Section gutterFirst={485} gutterSecond={146}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2025/03/residences-3-an-elegant-form.jpg" alt="" />
    </Section>

    <Section gutterFirst={191} gutterSecond={581}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2025/03/residences-4-an-elegant-form.jpg" alt="" />
    </Section>

    <Section4 gutterFirst={133.5} gutterSecond={133.5}>
      <div>
        <P><a href="https://www.shapeyourcity.ca/east-park" target="_blank">{ t('Explore the City of Vancouver\'s plan for the park here') }</a></P>
      </div>
    </Section4>

    <InfoSection ref={ interiorsRef } first={234} second={866} direction="row-reverse">
      <div>
        <div>
          <H3>{ t('Interiors') }</H3>
          <P>{ t('An abundance of space. A profusion of light. Understated design enriched by an elite materiality, with fine Italian finishes for a contemporary interpretation of classic luxury.') }</P>
        </div>
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2025/03/residences-5-interiors.jpg" alt="" />
      </div>
    </InfoSection>

    <Section gutterFirst={191}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2025/03/residences-6-marble-backsplash-kitchen.jpg" alt="" />
    </Section>

    <Section7 gutterFirst={191} first={297} second={536} gutterSecond={190}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/WineFridge_Detail.jpg" alt="" />
      </div>
      <div>
        <P>{ t('Marble backsplash and island front, history in its veining.') }</P>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Double_Oven_Detail-1.jpg" alt="" />
      </div>
    </Section7>

    <PercentageSection portionLeft={132} portionRight={576}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Double_Fridge_Detail-1.jpg" alt="" />
    </PercentageSection>

    <Section>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Kitchen_Stove_Detail.jpg" alt="" />
    </Section>

    <Section9 first={500} second={406} gutterFirst={132} gutterSecond={123}>
      <div>
        <P>{ t('The artistry of Poliform cabinetry. The quality of Gaggenau appliances.') }</P>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Stovetop_Detail.jpg" alt="" />
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Kitchen_Storage_Detail.jpg" alt="" />
      </div>
    </Section9>

    <PercentageSection portionLeft={501} portionRight={255}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Kitchen_Detail_KnifeBlock-1.jpg" alt="" />
    </PercentageSection>

    <PercentageSection portionLeft={191} portionRight={562}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Master_Ensuite.jpg" alt="" />
    </PercentageSection>

    <Section11 first={480} second={587}>
      <div>
        <P>{ t('Seamless marble counters, marble wall surrounds, heated marble floors. Warm rain showers cascading from above. A luxury of finishes. An indulgence in experience.') }</P>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Master_Ensuite_Detail.jpg" alt="" />
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/MasterBathroom_Vanity.jpg" alt="" />
        <P>{ t('A sculptural tub with freestanding faucet. A floating Poliform vanity with mirrored cabinetry, beautifully lit for function and feel.') }</P>
      </div>
    </Section11>

    <PercentageSection portionLeft={305} portionRight={305}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Ensuite_FreeStandingBath.jpg" alt="" />
    </PercentageSection>

    <Section13 gutterFirst={135} first={514} second={509}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Powder_Room.jpg" alt="" />
        <P>{ t('A walk through to a view. The magnificent master bedroom experience.') }</P>
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Bedroom_Detail-1.jpg" alt="" />
      </div>
    </Section13>

    <PercentageSection portionLeft={206.5} portionRight={206.5}>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Master_WIC.jpg" alt="" />
    </PercentageSection>

    <Section15 first={523} second={383} gutterSecond={118}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/WIC_Full.jpg" alt="" />
      </div>
      <div>
        <P>{ t('Custom-made Poliform closets in rich oak with integrated lighting, carefully crafted in Italy to house and display cherished items.') }</P>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Poliform_Closet_Detail.jpg" alt="" />
      </div>
    </Section15>

    <Section16 first={185} second={882}>
      <div>
        <P>{ t('Elegantly inset balconies, for year-round indulgence in the fresh sea air of a gentle coastal climate.') }</P>
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Patio_Final.jpg" alt="" />
      </div>
    </Section16>

    <Section17 first={547} second={425} gutterSecond={143}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Dining_Detail.jpg" alt="" />
      </div>
      <div>
        <P>{ t('The elevated simplicity of Savant home automation. Set your scene with a single touch: lighting, music, temperature, window shades.') }</P>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Home_Automation.jpg" alt="" />
      </div>
    </Section17>
  </Page>
}