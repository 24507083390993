import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';
import { HeroH1 } from '../components/Typography';
import FloorplanList from '../components/FloorplanList';
import FloorplanMenu from '../components/FloorplanMenu';
import { useFloorplans } from '../hooks';

const STICKY_MENU_HEIGHT = 100;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    letter-spacing: 2.63px;
    color: #C5B9AC;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    line-height: 30px;
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 30px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 36px 0;
      border-top: 1px solid #C5B9AC;

      &:last-child {
        border-bottom: 1px solid #C5B9AC;        
      }

      @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
        padding: 24px 0;
      }

    }

    button {
      color: #C5B9AC;
      width: 100%;
      background: none;
      outline: none;
      cursor: pointer; 

      @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.72px;
        padding: 0;
      }

      &.active {
        color: white;
      }
    }
  }
`

export default ({ match, history }) => {
  const { t } = useTranslation();
  const [ filteredFloorplans, setFilteredFloorplans ] = useState([]);
  const [ bedroomOptions, setBedroomOptions ] = useState([]);
  
  const [ collection, setCollection ] = useState(match.params.collection);
  const [ bedrooms, setBedrooms ] = useState(match.params.bedrooms || 'all');
  const [ plan, setPlan ] = useState(match.params.plan || 'all');

  const [ activePlan, setActivePlan ] = useState();
  
  const heroRef = useRef();
  const floorplanListRef = useRef();
  const floorplans = useFloorplans();

  useEffect(() => {
    setFilteredFloorplans(floorplans.filter(obj => obj.collection === collection && (bedrooms === 'all' || obj.bedroomSlug === bedrooms)));
  }, [floorplans, collection, bedrooms]);

  useEffect(() => {
    let found = {};
    let rooms = [];

    floorplans.forEach(obj => {
      if (obj.collection === collection) {
        const slug = obj.bedroomSlug;
        let label = `${obj.bedrooms} ${t('Bedroom')} ${t(obj.extraRoom)}`.trim();

        if (!found[slug]) {
          rooms.push({ slug, label });
        }

        found[slug] = true;
      }
    });
    rooms.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    setBedroomOptions(rooms);
  }, [floorplans, collection, t])

  useEffect(() => {
    const isZh = window.location.pathname.indexOf("/zh") === 0;
    let path = `${isZh ? '/zh' : ''}/floorplans/${collection}`;

    if (bedrooms !== 'all' || plan !== 'all') {
      path += `/${bedrooms}`;
    }

    if (plan !== 'all') {
      path += `/${plan}`;
    }
    history.replace(path);
  }, [ collection, bedrooms, plan, history ]);

  useEffect(() => {
    setActivePlan(plan === 'all' ? null : filteredFloorplans.find(obj => obj.slug === plan));
  }, [ plan, filteredFloorplans ]);

  const scrollToList = () => {
    window.scrollTo({ top: floorplanListRef.current.offsetTop - STICKY_MENU_HEIGHT, behavior: 'smooth' });    
  }

  const selectCollection = collection => {
    setPlan('all');
    setBedrooms('all');
    setCollection(collection);
    scrollToList();
  }

  const selectBedrooms = bedrooms => {
    setPlan('all');
    setBedrooms(bedrooms);
    scrollToList();
  }

  const selectPlan = plan => {
    setPlan(plan);
    if (plan === 'all') {
      scrollToList();
    }
  }

  const togglePlan = obj => {
    if (activePlan && obj.id === activePlan.id) {
      setPlan('all');
    } else {
      setPlan(obj.slug);
    }
  }

  return <Page title="Floorplans">
    <Hero ref={ heroRef } color="black" downArrow={false}>
      <Main>
        <div>
          <label>{ t('Select a Collection') }</label>
          <ul>
            <li><HeroH1 as="button" className={collection==='waterfront' ? 'active' : ''} onClick={() => selectCollection('waterfront')}>{ t('Waterfront Collection') }</HeroH1></li>
            <li><HeroH1 as="button" className={collection==='city' ? 'active' : ''} onClick={() => selectCollection('city')}>{ t('City Collection') }</HeroH1></li>
            {/* <li><HeroH1 as="button" className={collection==='townhome' ? 'active' : ''} onClick={() => selectCollection('townhome')}>{ t('Townhome Collection') }</HeroH1></li> */}
          </ul>
        </div>
      </Main>
    </Hero>
    <FloorplanMenu {...{collection, selectCollection, bedrooms, selectBedrooms, plan, selectPlan, bedroomOptions, filteredFloorplans, heroRef}} />
    <FloorplanList ref={ floorplanListRef } activePlan={ activePlan } floorplans={ filteredFloorplans } togglePlan={ togglePlan } offsetHeight={ STICKY_MENU_HEIGHT } />

  </Page>
}